// App.jsx
import React from 'react';
import styled from 'styled-components';
import rmLogo from './images/rmfoto.webp';
import './assets/RMNeue-Regular.woff2';

// Global Styles
const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  color: #43526e;
  font-family: 'RMNeue', sans-serif;
  padding: 20px;
  text-align: center;
  min-height: 100vh;
`;

// Title Styling
const Title = styled.h1`
  font-size: 2.5rem;
  color: #0f2145;
  margin-bottom: 10px;
`;

// Subtitle with Date
const Subtitle = styled.h2`
  font-size: 1.2rem;
  color: #0f2145;
  margin: 10px 0;
  font-weight: bold;
`;

// Image Styling
const LogoImage = styled.img`
  width: 1000px;
  height: auto;
  margin: 20px 0;
`;

// Main Text
const Text = styled.p`
  font-size: 1.1rem;
  line-height: 1.5;
  max-width: 600px;
  text-align: justify;
`;

function App() {
  return (
    <AppContainer>
      <Title>Comunicado Oficial</Title>
      <LogoImage src={rmLogo} alt="Real Madrid Logo" />
      <Subtitle>COMUNICADO OFICIAL • 14/11/2024</Subtitle>
      
      <Text>
        El Real Madrid desea comunicar la decisión tomada por nuestro querido jugador de Valorant, Youns, quien ha resuelto poner fin a su carrera internacional en el mundo del videojuego para dedicarse a nuevas metas y desafíos en otros ámbitos de su vida.
      </Text>
      <Text>
        Youns, cuyo talento y determinación lo llevaron a conquistar a millones de seguidores y a forjar una leyenda en el competitivo universo de Valorant, ha sido un ejemplo de entrega y pasión en cada encuentro, en cada partida. Su habilidad estratégica, su agilidad mental y su capacidad para liderar han dejado una marca imborrable en el mundo de los deportes electrónicos. Youns no solo ha sido un símbolo de nuestro equipo, sino también un pionero que ha inspirado a muchos jóvenes en el ámbito del gaming, dejando un legado de esfuerzo, profesionalismo y superación.
      </Text>
      <Text>
        Después de una carrera llena de éxitos y desafíos, Youns ha decidido enfocarse en nuevos horizontes, dedicando su energía y pasión a proyectos que lo llevarán a explorar facetas distintas a la competición virtual. Aunque su presencia será siempre añorada en el campo de Valorant, estamos seguros de que su camino estará marcado por éxitos allá donde decida emprender.
      </Text>
      <Text>
        El Real Madrid, sus compañeros, sus entrenadores y todos los aficionados le deseamos el mayor de los éxitos en esta nueva etapa de su vida. Siempre tendrá un lugar especial en nuestro corazón y será recordado como una leyenda que marcó un antes y un después en la historia del equipo.
      </Text>
      <Text>Youns, gracias por todo. ¡Hasta siempre, campeón!</Text>

      <Subtitle>Traduction en français</Subtitle>
      
      <Text>
        Le Real Madrid souhaite annoncer la décision de notre cher joueur de Valorant, Youns, qui a choisi de mettre un terme à sa carrière internationale dans le monde du jeu vidéo afin de se consacrer à de nouveaux objectifs et défis dans d’autres domaines de sa vie.
      </Text>
      <Text>
        Youns, dont le talent et la détermination ont conquis des millions de fans et forgé une légende dans l’univers compétitif de Valorant, a été un exemple de dévouement et de passion dans chaque rencontre, dans chaque partie. Sa capacité stratégique, son agilité mentale et son leadership ont laissé une empreinte indélébile dans le monde des sports électroniques. Youns n’a pas seulement été un symbole de notre équipe, mais aussi un pionnier qui a inspiré de nombreux jeunes dans le domaine du gaming, laissant en héritage des valeurs de persévérance, de professionnalisme et de dépassement de soi.
      </Text>
      <Text>
        Après une carrière remplie de succès et de défis, Youns a décidé de se tourner vers de nouveaux horizons, en investissant son énergie et sa passion dans des projets qui l’amèneront à explorer d’autres facettes loin de la compétition virtuelle. Bien que sa présence nous manquera dans l’arène de Valorant, nous sommes convaincus que son chemin sera marqué par la réussite, là où il choisira de s’engager.
      </Text>
      <Text>
        Le Real Madrid, ses coéquipiers, ses entraîneurs, ainsi que tous les fans, lui souhaitent le plus grand des succès dans cette nouvelle étape de sa vie. Il aura toujours une place spéciale dans notre cœur et sera toujours considéré comme une légende ayant marqué l’histoire de l’équipe.
      </Text>
      <Text>Youns, merci pour tout. Adieu, champion !</Text>
    </AppContainer>
  );
}

export default App;
